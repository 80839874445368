<template>
    <div class="dashboard cust-page-detail">
        <h1>支援計画書</h1>
        <div class="l-2-00-m--all">
            <div class="l-w9 cust-page-detail__data">
                <div class="l-mb2 l-2-10-m--all">
                    <div>
                        <div>作成日：{{ dataSupportDetail.created_date }}</div>
                        <div class="l-mt2">作成チーム：{{ creator }}</div>
                    </div>
                    <div v-if="role === '0'">
                        <button :class="`${classButtonStyle}other`" type="button" @click="$_modalOpen('releaseUpdate')">{{ setStateRelease }}</button>
                    </div>
                </div>
                <table class="cust-page-detail__data__table">
                    <tbody>
                        <tr>
                            <th>{{ dataSupportDetail.number }}</th>
                            <th colspan="2">対象者</th>
                            <td class="is-center" colspan="8" v-html="dataSupportDetail.target_person"></td>
                        </tr>
                        <tr>
                            <th>認知症のステージ</th>
                            <td class="is-center" colspan="10" v-html="dataSupportDetail.stage_of_dementia"></td>
                        </tr>
                        <tr>
                            <th>ケースのタイトル</th>
                            <td colspan="10" v-html="dataSupportDetail.case_title"></td>
                        </tr>
                        <tr>
                            <th>課題</th>
                            <td colspan="10" v-html="dataSupportDetail.task"></td>
                        </tr>
                        <tr>
                            <th>本人の主訴</th>
                            <td colspan="4" v-html="dataSupportDetail.person_chief_complaint"></td>
                            <th colspan="2">家族の主訴</th>
                            <td colspan="4" v-html="dataSupportDetail.family_chief_complaint"></td>
                        </tr>
                        <tr>
                            <th>支援目標</th>
                            <td colspan="10" v-html="dataSupportDetail.support_target"></td>
                        </tr>
                        <tr>
                            <th rowspan="12">支援内容</th>
                            <th>優先順位</th>
                            <th colspan="2">支援内容</th>
                            <th colspan="5">支援方法・引継先等</th>
                            <th colspan="2">連携先</th>
                        </tr>
                        <tr>
                            <td class="is-center" v-html="dataSupportDetail.support01_priority"></td>
                            <td class="is-center" colspan="2">受療支援</td>
                            <td colspan="5" v-html="dataSupportDetail.support01_method"></td>
                            <td class="is-center" colspan="2" v-html="dataSupportDetail.support01_cooperation"></td>
                        </tr>
                        <tr>
                            <td class="is-center" v-html="dataSupportDetail.support02_priority"></td>
                            <td class="is-center" colspan="2">身体の支援</td>
                            <td colspan="5" v-html="dataSupportDetail.support02_method"></td>
                            <td colspan="2" class="is-center" v-html="dataSupportDetail.support02_cooperation"></td>
                        </tr>
                        <tr>
                            <td class="is-center" v-html="dataSupportDetail.support03_priority"></td>
                            <td class="is-center" colspan="2">生活環境改善</td>
                            <td colspan="5" v-html="dataSupportDetail.support03_method"></td>
                            <td class="is-center" colspan="2" v-html="dataSupportDetail.support03_cooperation"></td>
                        </tr>
                        <tr>
                            <td class="is-center" v-html="dataSupportDetail.support04_priority"></td>
                            <td class="is-center" colspan="2">介護保険利用</td>
                            <td colspan="5" v-html="dataSupportDetail.support04_method"></td>
                            <td class="is-center" colspan="2" v-html="dataSupportDetail.support04_cooperation"></td>
                        </tr>
                        <tr>
                            <td class="is-center" v-html="dataSupportDetail.support05_priority"></td>
                            <td class="is-center" colspan="2">インフォーマル利用</td>
                            <td colspan="5" v-html="dataSupportDetail.support05_method"></td>
                            <td class="is-center" colspan="2" v-html="dataSupportDetail.support05_cooperation"></td>
                        </tr>
                        <tr>
                            <td class="is-center" v-html="dataSupportDetail.support06_priority"></td>
                            <td class="is-center" colspan="2">意思決定支援</td>
                            <td colspan="5" v-html="dataSupportDetail.support06_method"></td>
                            <td class="is-center" colspan="2" v-html="dataSupportDetail.support06_cooperation"></td>
                        </tr>
                        <tr>
                            <td class="is-center" v-html="dataSupportDetail.support07_priority"></td>
                            <td colspan="2" class="is-center">家族支援</td>
                            <td colspan="5" v-html="dataSupportDetail.support07_method"></td>
                            <td colspan="2" class="is-center" v-html="dataSupportDetail.support07_cooperation"></td>
                        </tr>
                        <tr>
                            <td class="is-center" v-html="dataSupportDetail.support08_priority"></td>
                            <td class="is-center" colspan="2">地域支援</td>
                            <td colspan="5" v-html="dataSupportDetail.support08_method"></td>
                            <td class="is-center" colspan="2" v-html="dataSupportDetail.support08_cooperation"></td>
                        </tr>
                        <tr>
                            <td class="is-center" v-html="dataSupportDetail.support09_priority"></td>
                            <td class="is-center" colspan="2">成年後見利用</td>
                            <td colspan="5" v-html="dataSupportDetail.support09_method"></td>
                            <td class="is-center" colspan="2" v-html="dataSupportDetail.support09_cooperation"></td>
                        </tr>
                        <tr>
                            <td class="is-center" v-html="dataSupportDetail.support10_priority"></td>
                            <td class="is-center" colspan="2">緊急対応</td>
                            <td colspan="5" v-html="dataSupportDetail.support10_method"></td>
                            <td class="is-center" colspan="2" v-html="dataSupportDetail.support10_cooperation"></td>
                        </tr>
                        <tr>
                            <td class="is-center" v-html="dataSupportDetail.support11_priority"></td>
                            <td class="is-center" colspan="2">その他</td>
                            <td colspan="5" v-html="dataSupportDetail.support11_method"></td>
                            <td class="is-center" colspan="2" v-html="dataSupportDetail.support11_cooperation"></td>
                        </tr>
                        <tr>
                            <th>残された課題</th>
                            <td colspan="7" v-html="dataSupportDetail.remaining_challenges_content"></td>
                            <td colspan="3" v-html="dataSupportDetail.remaining_challenges_attendees"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="l-w1 cust-page-detail__like">
                <div class="is-center">
                    <button :class="setStateLike.classButton" type="button" :style="isDisabledLike.style" :disabled="isDisabledLike.flag" @click="handleLikeUpdate">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z"/>
                        </svg>
                    </button>
                    <div :class="setStateLike.classNumber">{{ dataLike.total_value }}</div>
                </div>
            </div>
        </div>
        <h2>コメント</h2>
        <ValidationObserver ref="observer" v-slot="{ invalid }" class="l-mt1 l-w9" tag="div">
            <ValidationProvider v-slot="{ errors }" name="名前" rules="required" tag="div">
                <input v-model="inputComment.name" type="text" maxlength="10" placeholder="名前">
                <p class="errorMessage">{{ errors[0] }}</p>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" class="l-mt1" name="コメント内容" rules="required" tag="div">
                <textarea v-model="inputComment.comment" rows="4" placeholder="コメント欄">コメント入力欄</textarea>
                <p class="errorMessage">{{ errors[0] }}</p>
            </ValidationProvider>
            <button :class="X_validatedButtonStyle" class="l-mt1" :disabled="$_setValidatedDisabled(invalid)" type="button" @click="setModalCommentCreate()">送信</button>
        </ValidationObserver>
        <div class="l-mt2 l-w9 cust-page-detail__comment">
            <div v-for="(data, index) in listComments" :key="`list${index}`">
                <div>
                    <span class="l-mr4">{{ data.commented_at }}</span>
                    <span class="l-mr2">{{ data.team_name }}</span>
                    <span>{{ data.name }}</span>
                    <button v-if="role === '0'" class="l-ml4 button--text-delete" type="button" @click="getCommentId(data.id)">削除</button>
                </div>
                <p class="l-mt1">{{ data.comment }}</p>
            </div>
        </div>
        <Modal :modal="modal" @action="modalAction($event)" @close="$_modalClose()" />
    </div>
</template>

<script>
    "use strict";

    import apiListTeams from "@/mixins/apiListTeams";
    import common from "@/mixins/common";

    export default {
        "mixins": [
            apiListTeams,
            common,
        ],
        "props": {
            "id": {
                "default": "",
                "required": true,
                "type": String,
            },
            "creator": {
                "default": "",
                "required": true,
                "type": String,
            },
        },
        data() {
            return {
                "classButtonStyle": "button--",
                "commentId": "",
                "creatorTeamId": "",
                "dataLike": {},
                "dataSupportDetail": {},
                "listComments": [],
                "inputComment": {
                    "support_data_id": this.id,
                    "name": "",
                    "comment": "",
                },
                "isDisabledLike": {
                    "flag": false,
                    "style": {
                        "cursor": "",
                    },
                },
                "role": localStorage.getItem("role"),
                "searchKeywords": "",
                "stateLike": {
                    "classButton": "",
                    "classNumber": "",
                },
                "stateRelease": {
                    "text": "",
                },
            };
        },
        "computed": {
            setStateRelease() {
                // 公開ボタンの切り替え
                if (this.dataSupportDetail.deleted_at) {
                    this.stateRelease.text = "公開にする";
                } else {
                    this.stateRelease.text = "非公開にする";
                }
                return this.stateRelease.text;
            },
            setStateLike() {
                // いいねボタンの切り替え
                const classLike = "cust-page-detail__like";
                const classButton = `${classLike}__button`;
                const classNumber = `${classLike}__number`;
                if (this.dataLike.like === 1) {
                    this.stateLike.classButton = classButton;
                    this.stateLike.classNumber = classNumber;
                } else if (this.dataLike.like === 0) {
                    this.stateLike.classButton = `${classButton}--inactive`;
                    this.stateLike.classNumber = `${classNumber}--inactive`;
                }
                return {
                    "classButton": this.stateLike.classButton,
                    "classNumber": this.stateLike.classNumber,
                };
            },
        },
        created() {
            if (this.$route.query.search) {
                this.searchKeywords = this.$route.query.search.replace(/ /g, "　").split("　");
            }
            this.fetchingDataLike();
            this.fetchingDataSupportDetail();
            this.fetchingListComments();
            this.fetchingListTeams();
        },
        mounted() {
            if (this.role === "0") {
                // 管理者用 いいねボタンの無効化
                this.setDisabledLike();
            }
        },
        "methods": {
            modalAction(key) {
                if (key === "commentCreate") {
                    this.handleCommentCreate();
                } else if (key === "commentDelete") {
                    this.handleCommentDelete();
                } else if (key === "releaseUpdate") {
                    this.handleReleaseUpdate();
                } else if (key === "commentCreate--finished") {
                    this.$_modalClose();
                    this.inputComment.name = "";
                    this.inputComment.comment = "";
                    this.fetchingListComments();
                } else if (key === "commentDelete--finished") {
                    this.$_modalClose();
                    this.fetchingListComments();
                } else if (key === "releaseUpdate--finished") {
                    this.$_modalClose();
                    this.fetchingDataSupportDetail();
                }
            },
            getCommentId(id) {
                // 削除するコメントIDの取得
                this.commentId = id;
                this.$_modalOpen("commentDelete");
            },
            fetchingListTeams() {
                // 全チームデータ取得
                common.$_axios.get(
                    `${common.$_configs.apiUrl}/teams`,
                    this.$_requestConfig()
                ).then(response => {
                    this.listTeams = response.data;
                    this.getCreatorTeamId();
                }).catch(() => {
                    this.$_modalError("teamFetchError");
                });
            },
            getCreatorTeamId() {
                // 支援計画書作成チームIDの取得
                for (let i in this.listTeams) {
                    if (this.listTeams[i].team_name === this.creator) {
                        this.creatorTeamId = this.listTeams[i].team_id;
                    }
                    if (this.creatorTeamId === localStorage.getItem("teamId")) {
                        this.setDisabledLike();
                    }
                }
            },
            setModalCommentCreate() {
                // 管理者用 コメント機能の無効化
                if (this.role === "0") {
                    this.$_modalError("commentCreateError");
                } else {
                    this.$_modalOpen("commentCreate");
                }
            },
            setDisabledLike() {
                // いいねボタンの無効化
                this.isDisabledLike.flag = true;
                this.isDisabledLike.style.cursor = "default";
            },
            fetchingListComments() {
                // 個別支援記録データコメント取得
                common.$_axios.get(
                    `${common.$_configs.apiUrl}/support_data/comment/${this.id}`,
                    this.$_requestConfig()
                ).then(response => {
                    this.listComments = response.data;
                }).catch(() => {
                    this.$_modalError("commentFetchError");
                });
            },
            fetchingDataLike() {
                // 個別支援記録データいいね取得
                common.$_axios.get(
                    `${common.$_configs.apiUrl}/support_data/like/${this.id}`,
                    this.$_requestConfig()
                ).then(response => {
                    this.dataLike = response.data;
                }).catch(() => {
                    this.$_modalError("likeFetchError");
                });
            },
            fetchingDataSupportDetail() {
                // 個別支援記録データ取得
                common.$_axios.get(
                    `${common.$_configs.apiUrl}/support_data/${this.id}`,
                    this.$_requestConfig()
                ).then(response => {
                    const replaceData = {};
                    let responseData = response.data;
                    if (this.searchKeywords[0]) {
                        for (let searchKeyword of this.searchKeywords) {
                            const keyword = searchKeyword;
                            if (keyword !== "") {
                                for (let key in responseData) {
                                    if (key !== "deleted_at") {
                                        const textData = responseData[key] !== null ? String(responseData[key]) : "";
                                        replaceData[key] = textData.replace(new RegExp(keyword, "g"), `<strong class="marker">${keyword}</strong>`);
                                    } else {
                                        replaceData[key] = responseData[key];
                                    }
                                }
                            }
                            responseData = replaceData;
                        }
                        this.dataSupportDetail = replaceData;
                    } else {
                        this.dataSupportDetail = responseData;
                    }
                }).catch(() => {
                    this.$_modalError("supportDetailFetchError");
                });
            },
            handleLikeUpdate() {
                // 個別支援記録データいいね更新
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/support_data/like/${this.id}`,
                    {},
                    this.$_requestConfig()
                ).then(response => {
                    if (response.data.status_code === 200) {
                        this.fetchingDataLike();
                    } else {
                        this.$_modalError("likeUpdateError");
                    }
                }).catch(() => {
                    this.$_modalError("likeUpdateError");
                });
            },
            handleReleaseUpdate() {
                // 個別支援記録データ公開切替
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/support_data/switch_to_public/${this.id}`,
                    {},
                    this.$_requestConfig()
                ).then(response => {
                    if (response.data.status_code === 200) {
                        this.$_modalFinished();
                    } else {
                        this.$_modalError();
                    }
                }).catch(() => {
                    this.$_modalError();
                });
            },
            handleCommentCreate() {
                // 個別支援記録データコメント作成
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/support_data/comment`,
                    this.inputComment,
                    this.$_requestConfig()
                ).then(response => {
                    if (response.data.status_code === 200) {
                        this.$_modalFinished();
                    } else {
                        this.$_modalError();
                    }
                }).catch(() => {
                    this.$_modalError();
                });
            },
            handleCommentDelete() {
                // 個別支援記録データコメント削除
                common.$_axios.delete(
                    `${common.$_configs.apiUrl}/support_data/comment/${this.commentId}`,
                    this.$_requestConfig()
                ).then(response => {
                    if (response.data.status_code === 200) {
                        this.$_modalFinished();
                    } else {
                        this.$_modalError();
                    }
                }).catch(() => {
                    this.$_modalError();
                });
            },
        },
    };
</script>
